import React, { useRef, useEffect } from "react"
import { Grid } from "@material-ui/core"
import cx from "classnames"
import SearchIcon from "@material-ui/icons/Search"
import { useState } from "react"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import Layout from "src/components/layout.jsx"
import { loadStripe } from "@stripe/stripe-js"
import InsuranceDB from "src/helpers/insurance-provider-db.js"
import Autocomplete from "@material-ui/lab/Autocomplete"
import VisibilityIcon from "@material-ui/icons/Visibility"
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff"
import TextField from "@material-ui/core/TextField"
import jsonResult from "src/helpers/json-result.js"
import PropTypes from "prop-types"
import styles from "src/styles/components/zpp-page.module.scss"
import { useQueryParam, StringParam } from "use-query-params"
import getRegistrationCode from "src/helpers/get-registration-code.js"

let stripe
loadStripe(process.env.GATSBY_STRIPE_API_KEY).then(
  stripeReady => (stripe = stripeReady)
)

const LOGIN_URL = `${process.env.GATSBY_API_URL}/authenticate/`
const PAYMENT_SESSION_URL = `${process.env.GATSBY_API_URL}/website/stripe/session/`

const ERR_MSG_REQUIRED = "Dieses Feld ist ein pflichtfeld."
const ERR_MSG_TOO_SHORT = "Eingabe zu kurz"
const ERR_MSG_TOO_LONG = "Eingabe zu lang"
const ERR_MSG_INVALID_EMAIL = "Ungültige Email"

const LoginBillingFormSchemaShape = {
  username: Yup.string().trim(),
  email: Yup.string()
    .email(ERR_MSG_INVALID_EMAIL)
    .trim(),
  password: Yup.string()
    .min(6, ERR_MSG_TOO_SHORT)
    .max(30, ERR_MSG_TOO_LONG)
    .trim()
    .required(ERR_MSG_REQUIRED),
  terms: Yup.boolean()
    .equals([true])
    .required(ERR_MSG_REQUIRED),
}

const LoginBillingFormSchema = Yup.object().shape(LoginBillingFormSchemaShape)

export default function ZppPage(props) {
  if (
    props.hideInProduction &&
    process.env.GATSBY_ACTIVE_ENV === "production"
  ) {
    return null
  }

  const REGISTRATION_CODE = getRegistrationCode(props.registrationCode)

  const initialEmail = useQueryParam("email", StringParam)[0] || ""
  const initialUsername = useQueryParam("username", StringParam)[0] || ""

  const [providerCashback, setProviderCashback] = useState("")
  const [isSuccess, setIsSuccess] = useState(false)
  const [formError, setFormError] = useState("")
  const [plainTextPasswords, setPlainTextPasswords] = useState(false)
  const registrationFormRef = useRef()

  useEffect(() => {
    setIsSuccess(window.location.hash === "#success")
  }, [])

  const handleInsuranceProviderCheck = value => {
    if (!value) {
      setProviderCashback("")
      return
    }
    const { provider, discount } = value
    setProviderCashback(
      `Die ${provider} erstattet Dir ${discount} unseres Präventionkurses zurück.`
    )
  }

  const handleCheckout = (values, { setSubmitting }) => {
    setSubmitting(true)

    const url = LOGIN_URL
    const sendValues = {
      ...values,
      registration_code: REGISTRATION_CODE,
    }

    const error400msg = "Falsche Logindaten"

    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(sendValues),
    })
      .then(jsonResult)
      .then(({ token }) =>
        fetch(PAYMENT_SESSION_URL, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            priceId: props.priceId,
            email: values.email,
          }),
        })
      )
      .then(jsonResult)
      .then(({ session_id }) =>
        stripe.redirectToCheckout({ sessionId: session_id })
      )
      .catch(response => {
        setFormError(
          response.status === 400 ? error400msg : "Falsche Logindaten"
        )
        setSubmitting(false)
      })
  }

  const renderHeaderText = () => (
    <div className={styles.content}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <div className={styles.headerTextContainer}>
            <h1>Zertifizierter Onlinekurs mit der Mindance App</h1>
            <h1 className={styles.green}>
              {props.longCourseName} ({props.shortCourseName})
            </h1>
            <p>
              Dank unserer Koope­ra­tion mit den gesetz­li­chen Kran­ken­kas­sen
              werden Dir bis zu 100% der Kosten des Mindance{" "}
              {props.shortCourseName}-Kurses erstat­tet. Über die Höhe der
              Erstat­tung kannst Du Dich <a href="#search">hier</a>{" "}
              infor­mie­ren. Zusätzlich erhältst Du Zugang zu allen anderen
              Übungen der Mindance App.
            </p>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <img
            className={styles.fullWidth}
            src="/assets/img/spmr/zpp_graphic.png"
          />
        </Grid>
      </Grid>
    </div>
  )

  const renderIntro = () => (
    <div className={styles.intro}>
      <div className={styles.content}>
        <Grid container>
          <Grid item xs={12} md={4}>
            <h4 className={styles.green}>Was erwartet mich im Kurs?</h4>
            <p>{props.introduction}</p>
          </Grid>
          <Grid container item xs={12} md={4} className={styles.screenshotCol}>
            <div>
              <img src={props.image1} />
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <h4 className={styles.green}>Wie ist der Kurs aufgebaut?</h4>
            <p>{props.howIsTheCourseStructured}</p>
          </Grid>
        </Grid>
      </div>
    </div>
  )

  const renderTutorialStep = step => {
    switch (step) {
      case 1: {
        return (
          <div
            className={cx(styles.tutorialItem, {
              [styles.tutorialItemRight]: true,
            })}
            style={{ marginTop: 32 }}
          >
            <div className={styles.lineContainer}>
              <div className={styles.tutorialLine} />
              <div
                className={styles.tutorialPoint}
                style={{ backgroundColor: "#4CDBCE" }}
              />
            </div>
            <h4>Schritt 1:</h4>
            <p>
              Informiere Dich über die untenstehende Suchfunktion, welche Kosten
              Deine Krankenkasse übernimmt. Füll dann das Bestellformular am
              Ende dieser Seite aus, um den {props.shortCourseName} Kurs{" "}
              <b>kostenpflichtig für 75€</b> zu bestellen. Nach der Zahlung
              erhältst Du eine E-Mail mit dem Zahlungsbeleg. Hebe den Beleg gut
              auf, da Du ihn für eine Kostenerstattung später eventuell bei
              Deiner Krankenkasse einreichen musst. Den erstattungsfähigen{" "}
              {props.shortCourseName}-Kurs kannst Du ausschließlich auf dieser
              Webseite kaufen, nicht in der Smartphone-App.
            </p>
            <div id="search" className={styles.searchContainer}>
              <div className={styles.searchBox}>
                <h5>Kostenerstattung durch Deine Krankenkasse</h5>
                <p>
                  Such nach Deiner Krankenkasse, um zu prüfen, inwiefern die
                  Kosten des Kurses von Deiner Krankenkasse übernommen werden.
                </p>
                <div className={styles.form}>
                  <Autocomplete
                    options={InsuranceDB}
                    freeSolo
                    fullWidth
                    // disableClearable
                    getOptionLabel={option => option.provider}
                    onChange={(e, value) => {
                      handleInsuranceProviderCheck(value)
                    }}
                    renderInput={params => {
                      return (
                        <TextField
                          {...params}
                          style={{
                            backgroundColor: "#fff",
                            borderTopLeftRadius: 8,
                            borderBottomLeftRadius: 8,
                          }}
                          type="text"
                          name="provider"
                          variant="outlined"
                          placeholder="Name Deiner Krankenkasse"
                        />
                      )
                    }}
                  />
                  <button className={cx(styles.searchButton)} type="button">
                    <SearchIcon />
                  </button>
                </div>
                <p className={styles.searchResult}>{providerCashback}</p>
              </div>
            </div>
          </div>
        )
      }
      case 2: {
        return (
          <div className={cx(styles.tutorialItem, styles.tutorialItemRight)}>
            <div className={styles.lineContainer}>
              <div className={styles.tutorialLine} />
              <div
                className={styles.tutorialPoint}
                style={{ backgroundColor: "#0E8278" }}
              />
            </div>
            <h4>Schritt 2:</h4>
            <p>
              Nachdem Du alle acht Pflichtmodule des {props.shortCourseName}
              -Kurses in der App einmal <b>vollständig</b> durchlaufen hast,
              erhältst Du von uns eine E-Mail mit einem Link, unter dem Du Dein{" "}
              <b>Teilnahmezer­ti­fi­kat</b> herunterladen kannst. Füll die
              freien Felder des Formulars aus und reiche dieses nun mitsamt des
              Zahlungsbelegs bei Deiner Krankenkasse ein, um Deine
              (Teil-)Rückerstat­tung zu erhalten.
              <br />
              <br />
              Zusätzlich zum {props.shortCourseName} Kurs bekommst Du ein Jahr
              Zugang zu allen anderen Übungen der Mindance App.
              <br />
              <br />
              Nach 12 Mona­ten läuft Dein Zugang zu Mindance auto­ma­tisch aus.
              Du musst ihn dazu{" "}
              <b>
                nicht extra kün­di­gen, da es sich nicht um ein Abonnement
                handelt
              </b>
              .
            </p>
          </div>
        )
      }
    }
  }

  const renderTutorial = () => (
    <div className={cx(styles.content, styles.tutorial)}>
      <h4 className={styles.green}>Wie funktioniert’s?</h4>
      <Grid container className={styles.gridRoot}>
        <Grid item xs={12} md={6}>
          {renderTutorialStep(1)}
          {renderTutorialStep(2)}
        </Grid>
        <div className={styles.timeline} />
        <Grid item xs={12} md={6}>
          {renderTutorialStep(3)}
        </Grid>
      </Grid>
    </div>
  )

  const renderRegisterForm = () => (
    <Formik
      innerRef={registrationFormRef}
      initialValues={{
        username: initialUsername,
        email: initialEmail,
        password: "",
        terms: false,
      }}
      validationSchema={LoginBillingFormSchema}
      onSubmit={handleCheckout}
      isInitialValid={false}
    >
      {({ isSubmitting, isValid }) => (
        <Form>
          <Grid container spacing={2} justify="center" alignItems="center">
            <Grid item xs={12} sm={12}>
              <h2 className={cx(styles.green, styles.formTitle)}>
                Bestellformular
              </h2>
            </Grid>
            {!initialEmail ? (
              <Grid item xs={12} sm={12} className={styles.inputRow}>
                <p className={styles.inputLabel}>Email</p>
                <Field type="email" name="email" />
                <ErrorMessage
                  name="email"
                  className={cx(styles.errorText, "error")}
                  component="div"
                />
              </Grid>
            ) : (
              <Grid item xs={12} sm={12} className={styles.inputRow} />
            )}
            <Grid item xs={12} sm={12} className={styles.inputRow}>
              <p className={styles.inputLabel}>Passwort</p>
              <div className={styles.inputRow}>
                <Field
                  type={plainTextPasswords ? "text" : "password"}
                  name="password"
                />
                <button
                  className={styles.inputToggle}
                  type="button"
                  onClick={() => setPlainTextPasswords(v => !v)}
                >
                  {plainTextPasswords ? (
                    <VisibilityOffIcon />
                  ) : (
                    <VisibilityIcon />
                  )}
                </button>
              </div>
              <ErrorMessage
                name="password"
                className={cx(styles.errorText, "error")}
                component="div"
              />
            </Grid>
            <Grid item sm={1} xs={1} className={styles.checkboxContainer}>
              <Field type="checkbox" name="terms" />
            </Grid>
            <Grid item sm={10} xs={10} className={styles.acceptFormContainer}>
              <label className="noselect">
                {"Hiermit bestätige ich, dass ich die "}
                <a href="/healthprerequisites" target="_blank">
                  Gesundheitsvorraussetzungen
                </a>
                {" ERFÜLLE und stimme den "}
                <a href="/agb" target="_blank">
                  AGB
                </a>
                {", "}
                <a href="/privacy" target="_blank">
                  Datenschutzbestimmungen
                </a>
                {" zu."}
              </label>
            </Grid>
            <Grid sm={12} xs={12}>
              {<p className={cx(styles.formError, "error")}>{formError}</p>}
            </Grid>
            <Grid sm={12} xs={12}>
              <button
                type="submit"
                disabled={!isValid || isSubmitting}
                className={isSubmitting && "progress"}
              >
                Zahlungspflichtig für 75€ bestellen
              </button>
            </Grid>
            <Grid sm={12} xs={12}>
              <p className={styles.formFooterText}>
                Mit dieser Bestellung erwirbst Du den erstattungsfähigen&nbsp;
                {props.shortCourseName}-Kurs. Der gesamte Kurs dauert 8 Wochen
                und beinhaltet pro Woche ein Pflichtmodul a 60 Minuten.
              </p>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )

  const renderForm = () => (
    <div className={cx(styles.content, styles.formSection)}>
      <div className={styles.registerForm}>{renderRegisterForm()}</div>
    </div>
  )

  const renderSuccess = () => (
    <div className={cx(styles.content)}>
      <div className={styles.success}>
        <h4 className={styles.green}>
          Glückwunsch, Deine Bestellung war erfolgreich!
        </h4>
        <p>
          <b>
            Du erhältst nun eine Mail mit Deiner Rechnung sowie eine weitere
            Mail mit der Bestellbestätigung und den nächsten Schritten.
          </b>
        </p>
        <p>
          Falls Du keine Bestellbestätigung oder Rechnung erhalten haben
          solltest, schaue in Deinem Spam Ordner nach oder kontaktiere uns unter{" "}
          <a href="mailto:support@mindance.de">support@mindance.de</a>.
        </p>
      </div>
    </div>
  )

  return (
    <Layout className={styles.root}>
      {renderHeaderText()}
      {renderIntro()}
      {renderTutorial()}
      {isSuccess ? renderSuccess() : renderForm()}
    </Layout>
  )
}

ZppPage.propTypes = {
  registrationCode: PropTypes.string,
  priceId: PropTypes.string,
  hideInProduction: PropTypes.bool,
  shortCourseName: PropTypes.string,
  longCourseName: PropTypes.string,
  image1: PropTypes.string,
  howIsTheCourseStructured: PropTypes.string,
  introduction: PropTypes.string,
  test: PropTypes.string,
}
